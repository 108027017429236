<template>
  <div ref="app" id="app">
    <div
      v-if="isTFW && isShowContent"
      class="c-app tfw overflow-auto"
      :class="`${getOrgThemeStyle}`"
    >
      <TFWHeader class="header-bar" />
      <!-- <LoadingOverlay v-if="isLoading" /> -->
      <router-view></router-view>
      <Toaster />
      <FixedToaster />
    </div>
    <div v-else-if="isShowContent" class="c-app" :class="`${getOrgThemeStyle}`">
      <TheHeader class="header-bar" />
      <!-- <div ref="cbody" id="c-body-app" class="c-body"> -->
      <LoadingOverlay v-if="isLoading" />
      <Toaster />
      <FixedToaster />
      <EaseAccess />
      <SessionTimeout />
      <div>
        <Split ref="split1" :style="isSplitView ? 'height: calc(100vh)' : ''">
          <SplitArea :size="isSplitView ? 50 : 100">
            <div>
              <div id="leftPane">
                <router-view></router-view>
              </div>
            </div>
          </SplitArea>
          <SplitArea :size="50">
            <div v-if="isSplitView">
              <div id="rightPane"></div>
            </div>
          </SplitArea>
        </Split>
      </div>

      <!-- </div> -->
      <TheFooter></TheFooter>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Toaster from "@/components/reusable/Toaster";
import FixedToaster from "@/components/reusable/FixedToaster";
import TheHeader from "@/containers/TheHeader";
import EaseAccess from "@/containers/EaseAccess/EaseAccess";
import SessionTimeout from "@/containers/SessionTimeout";
import TheFooter from "@/containers/TheFooter";

//TFW
import TFWHeader from "@/pages/TFW/TFWHeader";


export default {
  name: "App",
  components: {
    Toaster,
    FixedToaster,
    TheHeader,
    TheFooter,
    EaseAccess,
    SessionTimeout,
    //------TFW Components------//
    TFWHeader,

  },
  data() {
    return {
      isSplitView: false,
    };
  },
  computed: {
    ...mapGetters([
      "scrollTop",
      "isLoading",
      "getOrgThemeStyle",
      "isShowContent",
    ]),
    isTFW() {
      return this.$route?.path.includes("/tfw") || false;
    },
  },
  methods: {
    handleKeyDown(event) {
      // Check if Ctrl+Shift+H is pressed
      if (event.ctrlKey && event.shiftKey && event.key === "H") {
        event.preventDefault(); // Prevent the default browser behavior (e.g., save page)

        this.isSplitView = !this.isSplitView;
        if (this.isSplitView) {
          this.clonePage();
        }
      }
    },
    clonePage() {
      setTimeout(() => {
        const leftPane = document.getElementById("leftPane");
        const rightPane = document.getElementById("rightPane");

        // Clone the HTML content
        const clonedContent = leftPane.cloneNode(true);

        // Clear existing content in the right pane
        rightPane.innerHTML = "";

        // Append the cloned content to the right pane
        rightPane.appendChild(clonedContent);
      }, 100);
    },
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeyDown);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeyDown);
  },
  watch: {
    scrollTop() {
      if (this.scrollTop) {
        const app = this.$refs.app;
        if (app) app.scrollTo(0, 0);
      }
    },
  },
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";

.split-container {
  display: flex;
  width: 100%;
}

.left-pane,
.right-pane {
  height: 100%;
  overflow: auto;
  padding: 10px;
  box-sizing: border-box;
}

.right-pane {
  width: 100%;
}

// .splitter {
//   width: 8px;

//   height: 100%;
//     float: left;

//     cursor: col-resize;
//     background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==);

//     background-color: #eee;
//     background-repeat: no-repeat;
//     background-position: 50%;
// }
</style>
